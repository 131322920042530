import { notify } from 'notiwind'

interface NotifyOptions {
  title: string
  text: string
  timeout?: number
}

const defaultTimeout = 4000

const useNotifier = () => {
  const notifySuccess = ({ title, text, timeout }: NotifyOptions): void => {
    notify({ group: 'app', title, text }, timeout || defaultTimeout)
  }

  const notifyError = ({ title, text, timeout }: NotifyOptions): void => {
    notify({ group: 'error', title, text }, timeout || defaultTimeout)
  }

  const notifyWarning = ({ title, text, timeout }: NotifyOptions): void => {
    notify({ group: 'warning', title, text }, timeout || defaultTimeout)
  }

  return { notifyError, notifySuccess, notifyWarning }
}

export default useNotifier
